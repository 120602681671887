@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
}

.amplify-liveness-cancel-button {
  display: none !important;
}
.amplify-flex .liveness-detector-check {
  gap: 0 !important;
}

.amplify-liveness-toast--medium {
  overflow: hidden !important;
}

.amplify-liveness-toast--medium .amplify-text {
  color: white !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.amplify-liveness-toast--medium .amplify-button--primary {
  border: 1px solid white;
  padding: 20px !important;
  width: auto !important;
}

.amplify-liveness-recording-icon {
  visibility: hidden !important;
}
@keyframes recordAnimation {
  0% {
    filter: drop-shadow(0px 0px 0px red);
    -webkit-filter: drop-shadow(0px 0px 0px red);
  }
  25% {
    filter: drop-shadow(0px 0px 2px red);
    -webkit-filter: drop-shadow(0px 0px 2px red);
  }
  50% {
    filter: drop-shadow(0px 0px 4px red);
    -webkit-filter: drop-shadow(0px 0px 4px red);
  }
  75% {
    filter: drop-shadow(0px 0px 2px red);
    -webkit-filter: drop-shadow(0px 0px 2px red);
  }
  100% {
    filter: drop-shadow(0px 0px 0px red);
    -webkit-filter: drop-shadow(0px 0px 0px red);
  }
}

.amplify-loader {
  display: none;
}

.amplify-liveness-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: none;
}

.amplify-alert--warning {
  visibility: hidden !important;
}

.amplify-liveness-camera-module {
  border: none !important;
  background-color: transparent !important;
}

.amplify-button--primary {
  transition: 0.3;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 20.5rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #a61932;
}

.amplify-button--primary:hover {
  box-shadow: 1px 2px 6px black;
}

.amplify-liveness-toast__message {
  color: white !important;
}

.amplify-liveness-toast--primary .amplify-liveness-toast__message,
.amplify-liveness-toast--primary {
  color: #a61932 !important;
}

.amplify-liveness-toast--primary .amplify-liveness-toast__message,
.amplify-liveness-toast--primary {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #a61932;
}

.amplify-liveness-match-indicator__bar:after {
  background-color: #a61932;
  background: #a61932;
}

.amplify-liveness-overlay {
  background-color: transparent !important;
}

.amplify-liveness-toast--medium {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 20.5rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #a61932;
}

/****************************TABLET DESIGN*******************************/

@media (min-width: 768px) and (max-width: 990px) {
}

/****************************DESKTOP DESIGN*******************************/

@media (min-width: 991px) {
}

/****************************MOBILE DESIGN*******************************/

@media (max-width: 767px) {
}
